<template>
  <div class="home">
    <h1 class="title">RPG Tools</h1>
    <h2>by @brlodi</h2>
    <p>
      A collection of useful browser tools for running and playing tabletop RPG
      games (TTRPGs), in particular 5th Edition Dungeons &amp; Dragons.
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Home',
});
</script>
