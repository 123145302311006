<template>
  <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/initiative">Initiative Tracker</router-link>
  </div>
  <router-view />
</template>

<style lang="scss">
#app {
  text-align: center;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
